import React, { useState } from 'react';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import CognitoConfig from './CognitoConfig';  
import NavigationBar from './components/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './login.css';

const userPool = new CognitoUserPool({
    UserPoolId: CognitoConfig.userPoolId,
    ClientId: CognitoConfig.clientId,
});

const Signup = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [stage, setStage] = useState('signup');  // 'signup' or 'verify'


    const handleSignup = (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');

        const attributeList = [
            {
                Name: 'name',
                Value: name
            },
            {
                Name: 'email',
                Value: email
            }
        ];

        userPool.signUp(username, password, attributeList, null, (err, result) => {
            if (err) {
                console.error(err);
                setError(err.message || JSON.stringify(err));
                setIsLoading(false);
            } else {
                console.log('Signup success:', result);
                setIsLoading(false);
                setStage('verify');  // Change stage to verification
            }
        });
        
    };
    const handleVerify = (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');
    
        const userData = {
            Username: username,
            Pool: userPool
        };
    
        const cognitoUser = new CognitoUser(userData);
        
        cognitoUser.confirmRegistration(verificationCode, true, function(err, result) {
            if (err) {
                console.error(err);
                setError(err.message || JSON.stringify(err));
                setIsLoading(false);
                return;
            }
            else {
                console.log('Verification success:', result);
                setIsLoading(false);
                // time to redirect to dashboard
                setTimeout(() => {
                    navigate('/dashboard');
                }, 2000); // 2000 milliseconds = 2 seconds
            }
            console.log('Verification success:', result);
            setIsLoading(false);
            // Redirect user or update UI to show successful verification
        });
    };
    

    return (
        <div>
            <NavigationBar />
            {stage === 'signup' && (
                <form onSubmit={handleSignup}>
                <div>
                    <label>Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Username</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div>
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Signing up...' : 'Sign Up'}
                </button>
                <div>  
                    <Link to="/login">Already have an account? Login here!</Link>
                </div>
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </form>
            )}
            {stage === 'verify' && (
            <form onSubmit={handleVerify}>
                <div>
                    <label>Verification Code</label>
                    <input
                        type="text"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Verifying...' : 'Verify'}
                </button>
                <div>
                    <Link to="/login">Already have an account? Login here!</Link>
                </div>
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </form>
        )}

        </div>
    );
};

export default Signup;