import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from './Auth';
import NavigationBar from './components/NavigationBar';
import './logout.css';

const Logout = () => {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(3);

    useEffect(() => {
        logoutUser();
        const timer = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (counter <= 0) {
            navigate('/');
        }
    }, [counter, navigate]);

    return (
        <div>
          <NavigationBar />
          <div className="logout">
            <h1>Logout Successful</h1>
            <p>Redirecting to the home page in {counter} seconds...</p>
          </div>
        </div>
      );
};

export default Logout;