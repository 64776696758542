// src/Auth.js
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import CognitoConfig from './CognitoConfig';

const userPool = new CognitoUserPool({
    UserPoolId: CognitoConfig.userPoolId,
    ClientId: CognitoConfig.clientId,
});

export const getCurrentUser = () => {
    return userPool.getCurrentUser();
};

export const getSession = async () => {
    const currentUser = getCurrentUser();
    if (currentUser) {
        return new Promise((resolve, reject) => {
            currentUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(session);
                }
            });
        });
    } else {
        return Promise.reject("No current user");
    }
};

export const getUserAttributes = async () => {
    const currentUser = getCurrentUser();
    if (currentUser) {
        return new Promise((resolve, reject) => {
            currentUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                } else {
                    currentUser.getUserAttributes((err, attributes) => {
                        if (err) {
                            reject(err);
                        } else {
                            const userAttributes = attributes.reduce((acc, attribute) => {
                                acc[attribute.Name] = attribute.Value;
                                return acc;
                            }, {});
                            resolve(userAttributes);
                        }
                    });
                }
            });
        });
    } else {
        return Promise.reject("No current user");
    }
};


export const logoutUser = () => {
    const currentUser = getCurrentUser();
    if (currentUser) {
        currentUser.signOut();
    }
};
