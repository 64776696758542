import React, { useState, useEffect, useRef } from 'react';
import { Container, InputGroup, FormControl, Button } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import './IdeaContainer.css';

const SimpleIdeaContainer = ({ onSendMessage, className }) => {
  const [userInput, setUserInput] = useState('');
  const [displayedText, setDisplayedText] = useState('');
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const inactivityTimeoutRef = useRef(null);

  useEffect(() => {
    if (!listening) {
      clearTimeout(inactivityTimeoutRef.current);
      inactivityTimeoutRef.current = null;
      if (transcript) {
        onSendMessage(transcript); // Call onSendMessage with the final transcript
        resetTranscript(); // Reset transcript for the next session
      }
    }
  }, [listening, transcript, onSendMessage, resetTranscript]);

  useEffect(() => {
    if (listening && transcript) {
      clearTimeout(inactivityTimeoutRef.current); // Clear any existing timeout
      // Set a new timeout when the transcript changes
      inactivityTimeoutRef.current = setTimeout(() => {
        SpeechRecognition.stopListening(); // Stop listening after 2 seconds of inactivity
      }, 2000); // Adjust this duration as needed
    }
  }, [transcript, listening]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Your browser does not support speech recognition.</span>;
  }

  const handleSendMessage = () => {
    if (userInput.trim()) {
      onSendMessage(userInput);
      setUserInput(''); // Clear input field after sending message
      setDisplayedText(userInput);
    }
  };

  const handleMicrophoneClick = () => {
    setUserInput(''); // Clear userInput when the mic button is pressed
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening({ continuous: true, interimResults: true });
    }
  };

  const handleClearInput = () => {
    setUserInput(''); // Clear userInput when the cross icon is clicked
    setDisplayedText('');
  };

  // ...

return (
  <Container className={className}>
    {className === 'idea-container-centered' && (
      <>
        <Button variant="dark" className={`mic-button-large ${listening ? 'pulse' : ''}`} onClick={handleMicrophoneClick}>
          <i className="bi bi-mic"></i>
        </Button>
        <h3>Press the microphone to Speak or Start typing your idea!</h3>
        <br />
      </>
    )}

    <InputGroup className="mb-3">
      {className === 'idea-container-centered' && (
        <>
          <FormControl
            placeholder='Type your idea here...'
            className='cusotm-input border-top border-start border-bottom border-end-0'
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
        <Button variant="outline-secondary" className='border-top border-end border-bottom border-start-0' onClick={handleClearInput}>
          <i className="bi bi-x-lg"></i>
        </Button>
        <Button variant="dark" onClick={handleSendMessage}>
          <i className="bi bi-send"></i>
        </Button>
        </>
      )}
      {className === 'idea-container-top' && (
        <>
          <FormControl
            placeholder='Type your idea here or press the microphone button to speak...'
            className='custom-input border-top border-start border-bottom border-end-0'
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <Button variant="outline-secondary" className='border-top border-end border-bottom border-start-0' onClick={handleClearInput}>
            <i className="bi bi-x-lg"></i>
          </Button>
          <Button variant="secondary" className={listening ? 'pulse' : ''} onClick={handleMicrophoneClick}>
            <i className="bi bi-mic"></i>
          </Button>
          <Button variant="dark" onClick={handleSendMessage}>
            <i className="bi bi-send"></i>
          </Button>

        </>
      )}
    </InputGroup>
    
    {/* <div className="idea">
      {listening ? transcript : displayedText}
    </div> */}

  </Container>
);
};

export default SimpleIdeaContainer;
