import React, { useState } from 'react';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import CognitoConfig from './CognitoConfig';  
import NavigationBar from './components/NavigationBar';
import { Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import './login.css';
import GoogleButton from 'react-google-button'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const userPool = new CognitoUserPool({
    UserPoolId: CognitoConfig.userPoolId,
    ClientId: CognitoConfig.clientId,
});
const Login = () => {
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');

        const user = new CognitoUser({
            Username: identifier, // This can be a username, email, or phone number
            Pool: userPool,
        });

        const authenticationData = {
            Username: identifier, // This can be a username, email, or phone number
            Password: password,
        };

        const authenticationDetails = new AuthenticationDetails(authenticationData);

        user.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                console.log('access token + ' + result.getAccessToken().getJwtToken());
                setIsLoading(false);
                // Redirect user to the secure page or handle session

                // Assuming the identifier is an email
                const userInfo = {
                    username: identifier,
                    password: password,
                };
                localStorage.setItem('accessToken', result.getAccessToken().getJwtToken());

                // axios.post(`${API_URL}/login`, userInfo)
                //     .then(response => {
                //         console.log('Login response:', response.data);
                //         const { access_token, id_token, refresh_token } = response.data.data;
                //     })
                //     .catch(error => {
                //         console.error('Error during login:', error);
                //     });

                navigate('/dashboard');
            },

            onFailure: (err) => {
                console.error('Login error:', err);
                setError(err.message || JSON.stringify(err));
                setIsLoading(false);
            },
        });
    };

    return (
        <div>
            <NavigationBar />
            <form onSubmit={handleLogin}>
                <div>
                    <label>Username / Email / Phone Number</label>
                    <input
                        type="text"
                        value={identifier}
                        onChange={(e) => setIdentifier(e.target.value)}
                    />
                </div>
                <div>
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button 
                    type="submit" 
                    disabled={isLoading}
                    style={{ display: 'block', marginBottom: '10px' }}
                >
                    {isLoading ? 'Logging in...' : 'Login'}
                </button>
                {/* <GoogleButton
                    onClick={() => window.location.href='https://synergii.auth.us-east-1.amazoncognito.com/login?client_id=6o42u38nv4vc0c8lfk34k0dr66&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fese5mxjzsh.us-east-2.awsapprunner.com%2F'}
                    style={{ display: 'block', marginBottom: '10px' }}
                >
                    Login with Social Media
                </GoogleButton> */}
                <a 
                    href='https://synergii.auth.us-east-1.amazoncognito.com/forgotPassword?client_id=6o42u38nv4vc0c8lfk34k0dr66&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fese5mxjzsh.us-east-2.awsapprunner.com%2F'
                    style={{ display: 'block', marginBottom: '10px', color: 'blue' }}
                >
                    Forgot Password
                </a>
                <div>
                    <Link to="/signup">Don't have an account? Sign up here!</Link>
                </div>
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </form>
        </div>
    );
};

export default Login;