// src/Dashboard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import { getUserAttributes } from './Auth';
import axios from 'axios';
import './Dashboard.css';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const Dashboard = () => {
    const [user, setUser] = useState({});
    const [ideas, setIdeas] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatedAttributes, setUpdatedAttributes] = useState({ name: '', email: '', phone_number: '' });
    const navigate = useNavigate();

    useEffect(() => {
        getUserAttributes()
            .then(attributes => {
                setUser(attributes);
                setUpdatedAttributes({ name: attributes.name, email: attributes.email, phone_number: attributes.phone_number });
                fetchUserData();
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching user attributes:", error);
                navigate('/login'); // Redirect to login if not authenticated
            });
    }, [navigate]);

    const fetchUserData = async () => {
        try {
            console.log('accessToken:', localStorage.getItem('accessToken'));
            const response = await axios.post(`${API_URL}/user_dashboard`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            setIdeas(response.data.ideas);
            setSessions(response.data.sessions);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedAttributes(prev => ({ ...prev, [name]: value }));
    };
    
    const updateUserInfo = async () => {
        try {
            const updatedAttributes = { username: "newUsername", email: "newEmail@example.com" };
    
            await axios.put(`${API_URL}/update_user`, updatedAttributes, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                }
            });
    
            alert('User info updated successfully!');
            setUser(updatedAttributes);
        } catch (error) {
            console.error('Error updating user info:', error);
            alert('Failed to update user info');
        }
    };
    
    const deleteAccount = async () => {
        try {
            await axios.delete(`${API_URL}/delete_user`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                }
            });
    
            alert('Account deleted successfully');
            navigate('/login');
        } catch (error) {
            console.error('Error deleting account:', error);
            alert('Failed to delete account');
        }
    };
    
    const IdeaCard = ({ idea }) => (
        <div className="card idea-card">
            <h3>Idea {idea.id}</h3>
            <p><strong>Prompt:</strong> {idea.prompt}</p>
            <p><strong>Response:</strong> {JSON.stringify(idea.response)}</p>
            <p><strong>Timestamp:</strong> {idea.timestamp}</p>
        </div>
    );

    const SessionCard = ({ session }) => (
        <div className="card session-card">
            <h3>Session {session.id}</h3>
            <p><strong>Started At:</strong> {session.started_at}</p>
            <p><strong>Ended At:</strong> {session.ended_at || 'Active'}</p>
            {/* <button onClick={() => deleteSession(session.id)}>Delete Session</button> */}
        </div>
    );

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <NavigationBar />
            <div className="dashboard-container">
                <h1>Dashboard</h1>
                <div className="user-info">
                    <h2>User Profile</h2>
                    <p>Welcome, {user.name || 'User'}!</p>
                    <div>
                        <label>
                            Name:
                            <input type="text" name="name" value={updatedAttributes.name} onChange={handleInputChange} />
                        </label>
                    </div>
                    <div>
                        <label>
                            Email:
                            <input type="email" name="email" value={updatedAttributes.email} onChange={handleInputChange} />
                        </label>
                    </div>
                    <div>
                        <label>
                            Phone Number:
                            <input type="text" name="phone_number" value={updatedAttributes.phone_number} onChange={handleInputChange} />
                        </label>
                    </div>
                    <button onClick={updateUserInfo}>Update Info</button>
                    <button onClick={deleteAccount} className="delete-account-btn">Delete Account</button>
                </div>

                <div className="sessions-section">
                    <h2>Sessions</h2>
                    {sessions.length > 0 ? (
                        <div className="sessions-container">
                            {sessions.map(session => (
                                <SessionCard key={session.id} session={session} />
                            ))}
                        </div>
                    ) : (
                        <p>No sessions found.</p>
                    )}
                </div>

                <div className="ideas-section">
                    <h2>Ideas Generated</h2>
                    {ideas.length > 0 ? (
                        <div className="ideas-container">
                            {ideas.map(idea => (
                                <IdeaCard key={idea.id} idea={idea} />
                            ))}
                        </div>
                    ) : (
                        <p>No ideas found.</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Dashboard;
