import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import Spinner from 'react-bootstrap/Spinner';
import "./IdeaChoiceCard.css";  // Ensure the path is correct

const IdeaChoiceCard = ({ idea, grants, onClick, className }) => {
    // Splitting the ideaText by newline to separate the title from the rest
    const [title, ...contentParts] = idea.split('\n');
    let confidence = contentParts.splice(-1, 1);  // Extracting the confidence score
    let targetCustomer = contentParts.splice(-2, 1);
    let marketSize = contentParts.splice(-3, 1);
    const content = contentParts.join('\n');
    console.log(grants);
    // Removing special characters from the title and also remove the word Title and no special characters
    const cleanTitle = title.replace(/[^\w\s]/gi, '').replace(/Title/g, '').trim();
    confidence = confidence.toString().replace(/[^\d]/g, ''); // Extracting numeric values for confidence
    //  Confidence score should be between 1 and 100
    confidence = Math.min(100, Math.max(1, confidence));
    
   
    const [selectedGrant, setSelectedGrant] = useState(null);
    const [showGrants, setShowGrants] = useState(false);  // State to control the visibility of the grants dropdown
    const [loading, setLoading] = useState(true);  // State to control the loading spinner
    
    useEffect(() => {
        // Fetch the grants here and set loading to false when the grants are fetched
        // For now, I'm just simulating a delay for fetching the grants
        setTimeout(() => {
            if (grants && grants.length > 0) {
                setShowGrants(true);  // Automatically uncollapse the bar when grants are available
                setLoading(false);  // Stop loading after the delay
            }
        }, 2000);
    }, [grants]);

    // Function to handle the click on "Related Grants"
    const handleGrantsClick = () => {
        // setLoading(true);  // Start loading
        setShowGrants(!showGrants);  // Toggle the visibility of the grants dropdown
        
    };

    if (confidence < 60) {
        return (
            <Card className={`${className} idea-choice-card`} onClick={onClick}>
                <Card.Body>
                    <Card.Title style={{ fontWeight: 'bold' }}>NA</Card.Title>
                    <Card.Text>I couldn't generate ideas for this prompt.</Card.Text>
                </Card.Body>
            </Card>
        );
    }


    return (
        <Card className={`${className} idea-choice-card`} onClick={onClick}>
            <Card.Body>
                <div className="card-header">
                    <Card.Title style={{ fontWeight: 'bold' }}>{cleanTitle}</Card.Title>
                    <div className="confidence-circle">{`${confidence}%`}</div>
                </div>

                <Card.Text>{content}</Card.Text>
                <Card.Text>{targetCustomer}</Card.Text>
                <Card.Text><strong>{marketSize}</strong></Card.Text>
                <div className="grants-section">
                        <div className="related-grants" onClick={handleGrantsClick}>
                            Related Grants
                            <div className="spinner-container">
                                {loading ? <Spinner animation="border" size="sm" /> : <i className="arrow down"></i>}
                            </div>
                        </div>
                    <Collapse in={showGrants}>
                        <div>            
                            {grants && grants.length > 0 && (
                
                                    <ul>
                                        {grants.map((grant, index) => (                                    
                                            <li key={index} onClick={() => setSelectedGrant(grant)}>
                                                <a href={`https://www.grants.gov/search-results-detail/${grant.opportunityid}`} target="_blank" rel="noopener noreferrer">
                                                    {grant.opportunitytitle}
                                                </a> 
                                                (<strong>{(grant.similarity_score * 100).toFixed(2)}%</strong> Match)
                                                
                                            </li>
                                        ))}
                                    </ul>
                            )}
                            {selectedGrant && (
                                <div className="grant-description">
                                    <h5>Description</h5>
                                    <p>{selectedGrant.description}</p>
                               </div>
                            )}
                                   
                        </div>

                    </Collapse>
                </div>
               
            </Card.Body>
        </Card>
    );
};

export default IdeaChoiceCard;