import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getCurrentUser } from './Auth';
import Login from './Login';
import Signup from './Signup';
import Dashboard from './Dashboard';  
import Logout from './Logout';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));

const ProtectedRoute = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const checkUser = async () => {
            try {
                const userSession = await getCurrentUser();
                setUser(userSession);
            } catch (error) {
                console.error('Error fetching user:', error);
                setUser(null);
            }
        };

        checkUser();
    }, []);

    return user ? children : <Login />;
};

root.render(
  
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
