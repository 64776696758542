import React, { useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { getSession, getUserAttributes } from '../Auth'; // Import Auth module

const NavigationBar = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const checkUser = async () => {
            try {
                const session = await getSession();
                const attributes = await getUserAttributes();
                if (session && attributes) {
                    setUser(attributes);
                }
            } catch (error) {
                console.log(error);
            }
        };
        checkUser();
    }, []);

    return (
        <Navbar variant="dark" expand="lg" sticky="top">
            <Nav.Item>
                <Navbar.Brand href="/" className='fs-3 font-weight-bold ms-4 fst-italic text-color brand'>
                    Synergii
                </Navbar.Brand>
            </Nav.Item>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Nav.Link href="/" className='fs-5 text-white me-2'>Home</Nav.Link>
                    <Nav.Link href="/dashboard" className='fs-5 text-white me-2'>Dashboard</Nav.Link>
                    {user ? (
                        <>
                            <Nav.Link className='fs-5 text-white me-2 login'>Hello, {user['name']}</Nav.Link>
                            <Nav.Link href="/logout" className='fs-5 text-white me-2 login'>Logout</Nav.Link>
                        </>
                    ) : (
                        <Nav.Link href="/login" className='fs-5 text-white me-2 login'>Login</Nav.Link>
                    )}
                </Nav> 
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavigationBar;