import React, { useState, useEffect } from 'react';
import './LandingText.css';

const messages = ['.....', 'Discover Grants', 'Apply to Grants', 'Manage Grants', 'Get Funded'];

const LandingText = () => {
  const [index, setIndex] = useState(0);
  const [message, setMessage] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage((prevMessage) => {
        if (isDeleting) {
          if (prevMessage === '') {
            setIsDeleting(false);
            setIndex((prevIndex) => (prevIndex + 1) % messages.length);
            return prevMessage;
          }
          return prevMessage.slice(0, -1);
        } else {
          const nextMessage = messages[index].slice(0, prevMessage.length + 1);
          if (nextMessage === messages[index]) {
            setTimeout(() => setIsDeleting(true), 1000); // Wait 1 second before starting to delete
            return nextMessage;
          }
          return nextMessage;
        }
      });
    }, isDeleting ? 100 : 200); // Adjust timing as needed

    return () => {
      clearTimeout(timer);
    };
  }, [isDeleting, index, message]);

  // Calculate the maximum length of the messages
  const maxLength = Math.max(...messages.map(message => message.length));

  return (
    <div className="landing-text">
      <h1 className="static-text">We help you&nbsp;</h1>  
      <h1 className="dynamic-text">{message.padEnd(maxLength, ' ')}<span className="cursor">|</span></h1>
    </div>
  );
};

export default LandingText;