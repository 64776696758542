  import React, { useState, useEffect } from 'react';
  import axios from 'axios';
  import NavigationBar from './components/NavigationBar';
  import IdeaContainer from './components/IdeaContainer';
  import IdeaChoiceCard from './components/IdeaChoiceCard';
  import LandingText from './components/LandingText';
  import ChatHistory from './components/ChatHistory'; // New component for chat history
  import './App.css';
  
  
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
  
  const getGrants = (ideas_json) => {
    // console.log(ideas_json);
    return axios.post(`${API_URL}/get_grants`, { ideas_list: ideas_json }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(grantsResponse => {
      console.log('Grants response:', grantsResponse);
      if (grantsResponse.data && grantsResponse.data.length > 0) {
        const grantsResponseData = grantsResponse.data.replace(/NaN/g, 'null');
        return JSON.parse(grantsResponseData);
      } else {
        // Handle the case where grantsResponse.data is empty
        console.log('No data in grantsResponse');
        return {};
      }
    })
    .catch(error => {
      console.error('Error getting grants:', error);
      throw error;
    });
  }
  
  
  const App = () => {
    const [ideas, setIdeas] = useState([]);
    const [selectedIdeas, setSelectedIdeas] = useState([]);
    const [activeIdeas, setActiveIdeas] = useState([]);
    const [initialGrants, setInitialGrants] = useState([]); 
    const [stat, setStat] = useState("I am Listening ...");  // "Generating Ideas ...", "Fetching Grants ...", "Done"
    const [chatHistory, setChatHistory] = useState([]);  // New state for chat history
    const [grants, setGrants] = useState([]);  
    const [isIdeaContainerCentered, setIdeaContainerCentered] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isVideoFinished, setIsVideoFinished] = useState(false);
    
    
    const handleSendMessage = (request, context, grants) => {
      return new Promise((resolve, reject) => {
        console.log("Request:", request); // Check if request is passed correctly
        console.log("Context:", context); // Check if context is passed correctly
        console.log("Grants:", grants); // Check if grants are passed correctly
        let prompt_ = "";
        if (context.length === 0 && grants.length === 0) {
          prompt_ = request;
        } else if (context.length !== 0 && grants.length === 0) {
          prompt_ = "User likes the following idea(s): " + context.join(" ") + ". Give more ideas based on this. " + request;
        } else if (grants.length !== 0) {
          const grantDescriptions = grants.map(grant => grant.description);
          console.log("Grant Descriptions:", grantDescriptions);
          prompt_ = "User likes the following idea(s): " + context.join(" ") + "They are also interested in getting these grants, here are the detailed grant descriptions: " + grantDescriptions + ". Generate ideas that align very well with these grants semantically. This is important. The ideas generated should be in the same field as the grants. Also it should be in line with the inital idea" + request ;
        }
        
        // Append user prompt to chat history
        appendToChatHistory(prompt_);
        
        let data = JSON.stringify({
          "prompt": prompt_,
          "model": "gpt-3.5-turbo"
        });
        
        axios.post(`${API_URL}/generate_ideas`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          // Append AI response to chat history
          appendToChatHistory(response.data.join(". "), false); // Assuming the response is an array of ideas
          resolve(response.data);
        })
        .catch(error => {
          console.error('Error sending message:', error);
          reject(error);
        });
      });
    };
    const handleIdeas = (request) => {
      let selectedIdeasText = selectedIdeas.map(idea => idea.idea);
      setIdeaContainerCentered(false);
      setStat("Generating Ideas with AI ...");
      console.log("Generating Ideas with AI ...");
      console.log([request]);
      getGrants([request])
      .then(initialGrants => {
        setInitialGrants(initialGrants[0]);
        console.log("Initial Grants:", initialGrants);
        
        // Return a resolved Promise to continue to the next then block
        return handleSendMessage(request, selectedIdeasText, initialGrants[0]);
      })
      .then(ideasResponse => {
        // First, update the ideas state with the generated ideas
        const initialIdeas = ideasResponse.map((idea, index) => ({ idea: idea.idea, grants: [] }));
        setIdeas(initialIdeas);
        setStat("Fetching the Most Relevant Grants from 2400 records ...");
        // Then, fetch grants for these ideas
        getGrants(ideasResponse.map(idea => idea.idea))
        .then(grantsDict => {
          // Update the ideas state again to include the grants information
          setGrants(grantsDict);
          console.log(grantsDict);
          setStat("Done");
        });
      })
      .catch(error => {
        console.error('Error handling ideas:', error);
      });
    }
    
    
    const appendToChatHistory = (message, isUser = true) => {
      setChatHistory(prev => [...prev, { id: Date.now(), text: message, isUser }]);
    };
    
    const handleSelectIdea = (index) => {
      const newSelectedIdeas = [...selectedIdeas, ideas[index]];
      setSelectedIdeas(newSelectedIdeas);
      
      const newActiveIdeas = activeIdeas.includes(index) ? activeIdeas.filter(i => i !== index) : [...activeIdeas, index];
      setActiveIdeas(newActiveIdeas);
    }
    
    return (
      <div className="app-container">
      
      <NavigationBar />
        <div>
        {isIdeaContainerCentered && <LandingText />}
        <IdeaContainer 
        onSendMessage={handleIdeas} 
        className={isIdeaContainerCentered ? 'idea-container-centered' : 'idea-container-top'}  
        />
        <div className="content-container">
        
        {!isIdeaContainerCentered && (
          <div className={`grants-container ${isExpanded ? 'expanded' : ''}`} onClick={() => setIsExpanded(!isExpanded)}>
          {/* <h5>Grants For Your Idea</h5> */}
          {initialGrants.length > 0 ? (
            <div>
            <p>Your idea has generated {initialGrants.length} relevant grants.</p>
            <table>
            <thead>
            <tr>
            <th>Grant Title</th>
            <th>Match Percentage</th>
            </tr>
            </thead>
            <tbody>
            {initialGrants.map((grant, index) => (
              <tr key={index}>
              <td>
              <a href={`https://www.grants.gov/search-results-detail/${grant.opportunityid}`} target="_blank" rel="noopener noreferrer">
              {grant.opportunitytitle}
              </a>
              </td>
              <td>
              <strong>{(grant.similarity_score * 100).toFixed(2)}%</strong>
              </td>
              </tr>
              ))}
              </tbody>
              </table>
              <p>Here are some ideas that align even better with these grants:</p>
              </div>
              ) : (
                <p>Your idea did not generate any relevant grants. However, here are some similar ideas that might be of interest:</p>
                )}
                </div>
                )}
                
                <div className="idea-container">
                
                {ideas.map((idea, index) => (
                  <IdeaChoiceCard
                  key={index}
                  idea={idea.idea} // Assuming 'idea' object has 'idea' property for text
                  grants={grants[index]} // Pass grants as a prop
                  onClick={() => handleSelectIdea(index)}
                  className={activeIdeas.includes(index) ? 'active card' : 'card'}
                  />
                  ))}
                  
                  </div>
                  <div className='status'>
                  {/* AI image logo big enough as h4*/}
                  <div class="robot-scale">
                  <div class="robot-shake">
                  <h2><i className="bi bi-robot robot"></i></h2>
                  </div>
                  </div>
                  
                  <h4>&emsp;{stat}</h4>
                  </div>
                  </div>
                  </div>
                  </div>
                  );
                  
                  
                };
                export default App;